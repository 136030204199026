import { createContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Keypair } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import axios from "axios";
import io from "socket.io-client";
import bs58 from "bs58";
import {
  getMint,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import "./App.css";
import NavBar from "./components/NavBar";
import SignupPage from "./pages/SignupPage";
import SigninPage from "./pages/SigninPage";

import LoadingDialog from "./components/Dialogs/LoadingDialog";
import NotifyAddressDialog from "./components/Dialogs/NotifyAddressDialog";
import { getTokenListByOwner } from "./utils/solana";
import { isValidAddress } from "./utils/methods";
import BotPage from "./pages/BotPage";

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/volume_bot_api`;
const WEBSOCKET_HOST = process.env.REACT_APP_SERVER_URL;
const PAGE_SIZE = 3;

export const AppContext = createContext(null);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { connection } = useConnection();
  const { connected, publicKey } = useWallet();

  const [loadingPrompt, setLoadingPrompt] = useState("");
  const [openLoading, setOpenLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [assets, setAssets] = useState([]);
  const [webSocket, setWebSocket] = useState(null);
  const [notifyStatus, setNotifyStatus] = useState({
    success: true,
    tag: "NONE",
  });

  const [notifyAddressDialog, setNotifyAddressDialog] = useState(false);
  const [notifyTitle, setNotifyTitle] = useState("");
  const [notifyAddress, setNotifyAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState("1234567890abcdefghijklmnopqrstuvwxyz");
  const [botZombieWallet, setBotZombieWallet] = useState("");
  const [startVolumeBot, setStartVolumeBot] = useState(false);
  const [startHolderBot, setStartHolderBot] = useState(false);
  const [newProjectDialog, setNewProjectDialog] = useState(false);
  const [holderMaxbuy, setHolderMaxBuy] = useState(0.5);
  const [sharkMaxbuy, setSharkMaxBuy] = useState(0.5);
  const [whaleMaxBuy, setWhaleMaxBuy] = useState(0.5);
  const [holderWallets, setHolderWallets] = useState([]);
  const [holderSOLBalanceData, setHolderSOLBalanceData] = useState([]);
  const [holderTokenBalanceData, setHolderTokenBalanceData] = useState([]);
  const [sharkSOLBalanceData, setSharkSOLBalanceData] = useState([]);
  const [sharkTokenBalanceData, setSharkTokenBalanceData] = useState([]);
  const [whaleSOLBalanceData, setWhaleSOLBalanceData] = useState([]);
  const [whaleTokenBalanceData, setWhaleTokenBalanceData] = useState([]);
  const [page, setPage] = useState(0);

  const openWebSocket = (userId) => {
    console.log("Starting websocket...");
    const ws = new io(WEBSOCKET_HOST);
    ws.on("connect", () => {
      console.log("WebSocket connection established");
      ws.emit("NEW_USER", userId);
    });

    ws.on("DISPERSE_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "DISPERSE_COMPLETED" });
      else setNotifyStatus({ success: false, tag: "DISPERSE_COMPLETED" });
    });

    ws.on("COLLECT_ALL_SOL", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "COLLECT_ALL_SOL" });
      else setNotifyStatus({ success: false, tag: "COLLECT_ALL_SOL" });
    });

    ws.on("LOG", (value) => {
      console.log("SERVER:", value);
    });

    ws.on("disconnect", () => {
      console.log("WebSocket connection closed");
      // setConnected(false);
    });

    ws.on("BOT_COMPLETED", async (value) => {
      const m = JSON.parse(value);
      if (m.message === "OK")
        setNotifyStatus({ success: true, tag: "BOT_TOKEN" }); // insufficient
      else setNotifyStatus({ success: false, tag: "BOT_TOKEN" }); // Jito fail
    });

    setWebSocket(ws);
  };

  const closeWebSocket = () => {
    if (webSocket) webSocket.close();
    setWebSocket(null);
  };

  const loadAllUsers = async () => {
    let newUsers = [];
    setLoadingPrompt("Loading all users...");
    setOpenLoading(true);
    try {
      console.log("Loading all users...");
      const { data } = await axios.get(`${SERVER_URL}/api/v1/user/load-all`, {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      });
      if (data.users) newUsers = data.users;
    } catch (err) {
      console.log(err);
      toast.warn("Failed to load users");
    }

    setOpenLoading(false);
    setUsers(newUsers);
  };

  const updateProject = (project) => {
    const newProjects = [...projects];
    for (let i = 0; i < newProjects.length; i++) {
      if (project._id === newProjects[i]._id) {
        newProjects[i] = project;
        break;
      }
    }
    setProjects(newProjects);
  };

  const holderTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setHolderTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setHolderTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setHolderTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const holderSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setHolderSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setHolderSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const sharkTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setSharkTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setSharkTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setSharkTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const sharkSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setWhaleSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setWhaleSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const whaleTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating Holder TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setWhaleTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setWhaleTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setWhaleTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const whaleSolBalances = async (wallets) => {
    try {
      console.log("Updating HOLDER SOL balances...", wallets);

      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        if (isValidAddress(wallets[i].address)) {
          console.log(
            `reading SOL balance of wallet ${wallets[i].address} `
          );
          try {
            balances[i] = Number(new BigNumber(await connection.getBalance(new PublicKey(wallets[i].address)) + "e-"+ 9).toFixed(4, 1));
          } catch (err) {
            console.log(err);
          }
        }
      }
      setSharkSOLBalanceData(balances);
      console.log("Updated all Bot Sol balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setSharkSOLBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const allWalletRefresh = async () => {
    const holer = currentProject.botWallets.filter(element => element.kind === "holder");
    const pageHolders = holer.splice((page+1) * PAGE_SIZE, PAGE_SIZE);
    const shark = currentProject.botWallets.filter(element => element.kind === "shark");
    const whale = currentProject.botWallets.filter(element => element.kind === "whale");
    console.log("page : ", page);
    holderSolBalances(pageHolders);
    holderTokenBalances(currentProject.name, pageHolders);
    sharkSolBalances(shark);
    sharkTokenBalances(currentProject.name, shark);
    whaleSolBalances(whale);
    whaleTokenBalances(currentProject.name, whale);
  }

  const logout = async () => {
    console.log("Logging out...");

    setLoadingPrompt("Logging out...");
    setOpenLoading(true);
    try {
      await axios.get(`${SERVER_URL}/api/v1/user/logout`, {
        headers: {
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      });
      localStorage.removeItem("access-token");

      setUsers([]);
      setProjects([]);
      setCurrentProject({});
      setUser(null);
      closeWebSocket();
    } catch (error) {
      console.log(error);
      toast.warn("Failed to logout");
    }
    setOpenLoading(false);
  };

  useEffect(() => {
    if (currentProject.name) {
      console.log("currentProject.token:", currentProject.name);
      if (currentProject.name) {
        setTokenAddress(currentProject.name);
      }
      
      let holder = currentProject.botWallets.filter(element => element.kind === "holder").slice(0, 3);
      setHolderWallets(holder);

      if (currentProject.botZombieWallet) {
        setBotZombieWallet(currentProject.botZombieWallet);
        setHolderMaxBuy(currentProject.maxBuy[0]);
        setSharkMaxBuy(currentProject.maxBuy[1]);
        setWhaleMaxBuy(currentProject.maxBuy[2]);
      } else setBotZombieWallet("");
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject.botWallets && currentProject.botWallets.length > 0) {
      let holder = currentProject.botWallets.filter(element => element.kind === "holder").slice(0, 3);
      setHolderWallets(holder);
      allWalletRefresh()
    } 

  }, [currentProject.botWallets]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!user) {
      if (
        location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/register"
      ) {
        navigate("/login");
      }
    } else {
      navigate("/");
    }
  }, [location, navigate, user]);

  useEffect(() => {
    if (user) {
      console.log("Succeed to login");
      toast.success("Succeed to login");

      openWebSocket(user._id);

      const accessToken = localStorage.getItem("access-token");
    } else console.log("Logged out");
  }, [user]);

  useEffect(() => {
    if (notifyStatus.tag === "COLLECT_ALL_SOL") {
      if (notifyStatus.success) toast.success("Succeed to collect all SOL!");
      else toast.warn("Failed to collect all SOL!");
      setOpenLoading(false);
    }
    if (notifyStatus.tag === "DISPERSE_COMPLETED") {
      if (notifyStatus.success) toast.success("Succeed to disperse!");
      else toast.warn("Insufficient SOL!");
      setOpenLoading(false);
    }

    if (notifyStatus.tag === "BURN_TOKEN") {
      if (notifyStatus.success) toast.success("Succeed to burn token!");
      else toast.warn("Failed to burn token!");
      setOpenLoading(false);
    }

    if (notifyStatus.tag === "BOT_TOKEN") {
      if (notifyStatus.success) toast.warn("In sufficient SOL!");
      else toast.warn("Jito failed");
      setOpenLoading(false);
    }
  }, [notifyStatus]);

  useEffect(() => {
    if (connected) {
      console.log("Getting token accounts...");
      getTokenListByOwner(connection, publicKey, true).then((response) => {
        setAssets(response);
        console.log("Success");
      });
    } else setAssets([]);
  }, [connected, connection, publicKey]);

  return (
    <AppContext.Provider
      value={{
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
        logout,
        user,
        setUser,
        users,
        setUsers,
        projects,
        setProjects,
        currentProject,
        setCurrentProject,
        assets,
        setAssets,
        webSocket,
        setWebSocket,
        openWebSocket,
        closeWebSocket,
        loadAllUsers,
        updateProject,
        notifyStatus,
        setNotifyStatus,
        setNotifyTitle,
        setNotifyAddress,
        setNotifyAddressDialog,
        setTokenAddress,
        tokenAddress,
        setBotZombieWallet,
        botZombieWallet,
        startHolderBot,
        startVolumeBot,
        setStartHolderBot,
        setStartVolumeBot,
        setNewProjectDialog,
        newProjectDialog,
        holderMaxbuy,
        sharkMaxbuy,
        whaleMaxBuy,
        setHolderMaxBuy,
        setSharkMaxBuy,
        setWhaleMaxBuy,
        setHolderWallets,
        holderWallets,
        holderSOLBalanceData,
        setHolderSOLBalanceData,
        holderTokenBalanceData,
        setHolderTokenBalanceData,
        sharkSOLBalanceData,
        setSharkSOLBalanceData,
        sharkTokenBalanceData,
        setSharkTokenBalanceData,
        whaleSOLBalanceData,
        setWhaleSOLBalanceData,
        whaleTokenBalanceData,
        setWhaleTokenBalanceData,
        holderSolBalances,
        holderTokenBalances,
        sharkSolBalances,
        sharkTokenBalances,
        whaleSolBalances,
        whaleTokenBalances,
        allWalletRefresh,
        setPage,
        page     
      }}
    >
      <LoadingDialog isOpen={openLoading} prompt={loadingPrompt} />
      <NotifyAddressDialog
        isOpen={notifyAddressDialog}
        title={notifyTitle}
        address={notifyAddress}
        onClose={() => setNotifyAddressDialog(false)}
      />
      {user ? (
        <div className="flex flex-col min-h-[100vh] overflow-x-hidden backdrop-blur-md">
          <div className="relative flex items-start justify-between w-full h-max">
            <div className="flex flex-col w-full 2xl:w-full h-screen">
              <div className="px-1 relative">
                <NavBar className="flex w-full py-2" />
                <div className="w-full h-full overflow-y-auto">
                  <Routes>
                    <Route path="/" element={<BotPage />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/register" element={<SignupPage />} />
          <Route path="/login" element={<SigninPage />} />
          <Route path="/" element={<SigninPage />} />
        </Routes>
      )}
    </AppContext.Provider>
  );
}

export default App;
